import {
  CooldownInterface,
  ExerciseCardClass,
  SessionDetailInterface,
  SessionInterface,
  WarmupInterface,
  WeekConfig,
  WorkoutInterface,
} from '../../tsUtils';
import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { createSelector } from 'reselect';
import { sessionsFail } from '../sessions/reducers';
import { add } from 'lodash';

export interface WorkoutState extends EntityState<WorkoutInterface> {
  selectedId?: number;
  loading: boolean;
  loaded: Boolean;
  error: any | null;
}

const workoutAdapter = createEntityAdapter<WorkoutInterface>({});

export const workoutInitialState: WorkoutState = workoutAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export const workoutSlice = createSlice({
  name: 'workouts',
  initialState: workoutInitialState,
  reducers: {
    workoutsLoading(state: WorkoutState) {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    workoutsLoaded(state: WorkoutState) {
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
    workoutsFail(state: WorkoutState, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
    getWorkoutsSuccess(state: WorkoutState, action) {
      workoutAdapter.upsertMany(state, action.payload);
    },
    createWorkoutSuccess(state: WorkoutState, action) {
      workoutAdapter.upsertOne(state, action.payload);
    },
    deleteWorkoutSuccess(state: WorkoutState, action) {
      workoutAdapter.removeOne(state, action.payload);
    },
    renameWorkoutSuccess(state: WorkoutState, action: { payload: WorkoutInterface }) {
      workoutAdapter.upsertOne(state, action.payload);
    },
    setSelectedWorkoutId(state: WorkoutState, action) {
      state.selectedId = action.payload;
    },
    clearSelectedWorkout(state: WorkoutState) {
      let newState = state;
      delete newState.selectedId;
      state = newState;
    },
    addSessionSuccess(state: WorkoutState, action: { payload: SessionInterface }) {
      state.entities[action.payload.workout]?.sessions?.push({
        id: action.payload.id!,
        order: action.payload.order,
      });
    },
    removeWorkoutSession(
      state: WorkoutState,
      action: { payload: { id: number; workout_id: number } },
    ) {
      if (
        state.entities[action.payload.workout_id]?.sessions &&
        state.entities[action.payload.workout_id]?.sessions.length === 1
      ) {
        workoutAdapter.removeOne(state, action.payload.workout_id);
      } else {
        state.entities[action.payload.workout_id]?.sessions?.filter(
          session => session.id !== action.payload.id!,
        );
      }
    },
    updateWorkoutSuccess: workoutAdapter.updateOne,
    addWarmupToWorkout(state: WorkoutState, action: { payload: { workout_id: number; warmup: WarmupInterface } }) {
      if (state.entities[action.payload.workout_id]) {
        state.entities[action.payload.workout_id]?.warmups.push(action.payload.warmup);
      }
    },
    addWarmupsToWorkout(state: WorkoutState, action: { payload: { workout_id: number; warmups: WarmupInterface[] } }) {
      if (state.entities[action.payload.workout_id]) {
        state.entities[action.payload.workout_id]!.warmups = [...action.payload.warmups];
      }
    },
    addCooldownToWorkout(state: WorkoutState, action: { payload: { workout_id: number; cooldown: CooldownInterface } }) {
      if (state.entities[action.payload.workout_id]) {
        state.entities[action.payload.workout_id]?.cooldowns.push(action.payload.cooldown);
      }
    },
    addCooldownsToWorkout(state: WorkoutState, action: { payload: { workout_id: number; cooldowns: CooldownInterface[] } }) {
      if (state.entities[action.payload.workout_id]) {
        state.entities[action.payload.workout_id]!.cooldowns = [...action.payload.cooldowns];
      }
    },
  },
});

export const {
  workoutsLoading,
  workoutsLoaded,
  workoutsFail,
  setSelectedWorkoutId,
  getWorkoutsSuccess,
  createWorkoutSuccess,
  deleteWorkoutSuccess,
  updateWorkoutSuccess,
  renameWorkoutSuccess,
  addSessionSuccess,
  removeWorkoutSession,
  addWarmupToWorkout,
  addCooldownToWorkout,
  addWarmupsToWorkout,
  addCooldownsToWorkout,
} = workoutSlice.actions;

export const workoutSelectors = workoutAdapter.getSelectors<RootState>(state => state.workouts);

export const selectProgramWorkouts = createSelector(
  [
    (state: RootState) => workoutSelectors.selectAll(state),
    (state: RootState, program_id: number) => program_id,
  ],
  (workouts, program_id) => {
    return workouts.filter(workout => workout.program === program_id);
  },
)

export const selectWorkoutWithSessions = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState) => state.sessions.entities,
    (state: RootState) => state.exercise_cards.entities,
    (state: RootState, workout_id: number) => workout_id,
  ],
  (workoutEntities, sessionEntities, cardEntities, workout_id) => {
    const workout = workoutEntities[workout_id];
    let sessions: SessionDetailInterface[] = [];
    if (workout) {
      sessions = workout?.sessions.reduce((a, c): SessionDetailInterface[] => {
        let session = sessionEntities[c.id];
        if (session) {
          const exercise_cards = session.exercise_cards.reduce((a, c): ExerciseCardClass[] => {
            const card = cardEntities[c.id];
            if (card) {
              return [...a, card];
            }
            return a;
          }, [] as ExerciseCardClass[]);
          a.push({
            ...session,
            exercise_cards,
          });
        }
        return a;
      }, [] as SessionDetailInterface[]);
    }

    return {
      id: workout?.id,
      program: workout?.program!,
      name: workout?.name!,
      order: workout?.order!,
      warmups: workout?.warmups!,
      cooldowns: workout?.cooldowns!,
      modified: workout?.modified!,
      sessions,
    };
  },
);

export const getSelectedWeekSessions = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState) => state.sessions.entities,
    (state: RootState, week: WeekConfig) => week,
  ],
  (workouts, sessions, week) => {
    return Object.values(week).reduce<string[]>((acc, curr) => {
      curr.forEach(programConfig => {
        let session = sessions[programConfig.session_id];
        let workout = workouts[programConfig.workout_id];
        if (session && workout) {
          acc.push(`${workout.name}: ${session.name}`);
        }
      });
      return acc;
    }, []);
  },
);

export const getSelectedWorkout = createSelector(
  [
    (state: RootState) => state.workouts.selectedId,
    (state: RootState) => workoutSelectors.selectEntities(state),
  ],
  (selectedId, workouts) => (selectedId ? workouts[selectedId] : null),
);

export const selectWorkoutsById = createSelector(
  [
    (state: RootState) => state.workouts.entities,
    (state: RootState, ids: { id: number; order: number }[]) => ids,
  ],
  (workoutEntity, ids) => {
    const workouts = ids.reduce<WorkoutInterface[]>((a, id) => {
      const workout = workoutEntity[id.id];
      if (workout) {
        a.push(workout);
      }
      return a;
    }, []);

    return workouts;
  },
);
